<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { GlobalState } from './GlobalState';

export default {
  data(){
    return GlobalState;
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&family=Teko&display=swap');

h1, h2, h3, h4, h5, h6 {
  font-family: 'Teko', sans-serif;
}

.screen {
  display: flex;
  flex-direction: column;
}
.screen-content {
  flex: 1;
}
#app {
  font-family: 'Roboto', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}
body {
  background-color: #eeeeee;
}
body, html, #app, .screen {
  width: 100%;
  height: 100%;
}
body, html {
  padding: 0;
  margin: 0;
}

.theme-button {
  background-color: #6ae4ff;
  color: #065263;
  transition: all 0.3s ease;
}
.theme-button:hover {
  background-color: #58cde7;
}
.theme-input:focus {
  /* box-shadow: inset 0 0 0 2px #2f4b63; */
}
.theme-input::placeholder {
  color: #188ca3;
}
.theme-input {
  background-color: #e7fbff;
  /* box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.014); */
  color: #1f1f1f;
  transition: all 0.3s ease;
}

.theme-button, .theme-input {
  margin: 0;
  padding: 10px 16px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  outline: none;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}
.theme-link {
  color: #0068a5;
}
.card {
  background-color: white;
  border-radius: 16px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.055);
}
</style>
