<template>
  <div class="screen">
    <Nav/>
    <div class="screen-content">
      <div class="left">
        <div class="top">
          <div class="card">
            <h2>Title</h2>
            <p>text</p>
          </div>
        </div>
        <div class="bottom">
          <div class="card">
            <h2>Play</h2>
            <div>
              <button @click="createGame()" class="theme-button">Play</button>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="card">
          <h2>Active games (0)</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createGame } from '../api'
export default {
  name: 'Home',
  components: {
  },
  methods:{
    createGame(){
      createGame(localStorage.getItem("accesstoken"), 10,10, 10).then(gameId=>{
        this.$router.push("minesweeper/"+gameId)
      });
    }
  }
}
</script>

<style scoped>
  .screen-content {
    display: flex;
    flex-direction: row;
  }
  h2, p {
    margin: 0;
  }
  .left, .right {
    margin: 20px;
  }
  .top {
    margin-bottom: 20px;
  }
  .left {
    display: flex;
    flex-direction: column;
  }
  .bottom {
    flex: 1;
  }
  .top, .bottom, .right {
    display: flex;
  }
  .card {
    padding: 20px;
    flex: 1;
  }
  .left {
    flex: 2;
    margin-right: 0;
  }
  .right {
    flex: 1;
  }
</style>