<template>
    <div id="nav">
      <router-link to="/" exact>Home</router-link>
      <router-link to="/user/kess" v-if="GlobalState.user">{{ GlobalState.user.username }}</router-link>
      <router-link to="/settings" v-if="GlobalState.user">Settings</router-link>
      <router-link to="/login" v-if="!GlobalState.user">Log in</router-link>
    </div>
</template>

<script>
import { GlobalState } from '../GlobalState';

export default {
    data(){
        return {
            GlobalState
        }
    }
}
</script>

<style lang="css" scoped>


#nav {
    display: flex;
    
    justify-content: center;
    align-items: center;
    background-color: #5A5766;
}
a {
    color: #e6e6e6;
    text-decoration: none;
    padding: 10px 20px;
}
a:hover {
    color: #e6e6e6ab;
    background-color: rgba(0, 0, 0, 0.1);
}
a.router-link-active {
    color: #9792E3;
}
</style>