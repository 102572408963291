import { GlobalState } from "./GlobalState";

let isDev = window.location.hostname.endsWith("localhost");

let endpoint = "http://localhost:3000/";
if(!isDev){
    endpoint = "https://api.simplegames.eu.org/";
}

export async function getUserByName(name){
    let obj = await fetch(endpoint+"userInfo/"+name).then(r=>r.json());
    obj.dateJoined = new Date(obj.dateJoined);
    return obj;
}
export async function getOwnUser(token){
    let obj = await fetch(endpoint+"user/info", {
        headers: {
            "Authorization": "Bearer "+token,
        }
    }).then(r=>r.json());
    obj.dateJoined = new Date(obj.dateJoined);
    return obj;
}
export async function processLogin(token){
    GlobalState.user = await getOwnUser(token);
}

export function logout(){
    localStorage.removeItem("accesstoken");
    GlobalState.user = null;
}

export async function login(username,password){
    let token = await getAccessToken(username,password);
    localStorage.setItem("accesstoken",token);
    processLogin(token);
}
export async function deleteAccount(token){
    return fetch(endpoint+"user/delete",{
        headers:{
            "Authorization": "Bearer "+token,
        }
    }).then(r=>{
        if (r.status == 204){
            return;
        } else {
            throw new Error("Could delete account");
        }
    })
}
export async function getGameInfo(gameId){
    return fetch(endpoint+"ms/gameInfo/"+gameId).then(r=>{
        if (r.status == 200){
            return r.json();
        } else {
            throw new Error("Could get game info");
        }
    })
}
export async function giveUp(token,gameId){
    return fetch(endpoint+"user/ms/giveup/"+gameId,
    {
        headers:{
            "Authorization": "Bearer "+token,
        }
    }).then(r=>{
        if (r.status == 204){
            return;
        } else {
            throw new Error("Could give up game");
        }
    })
}
export async function createGame(token,width,height,bombCount){
    return fetch(endpoint+"user/ms/createGame?"+new URLSearchParams({
        width,
        height,
        bombCount,
    }),
    {
        headers:{
            "Authorization": "Bearer "+token,
        }
    }).then(r=>{
        if (r.status == 200){
            return r.text();
        } else {
            throw new Error("Could not create game");
        }
    })
}
export async function register(username,password){
    return fetch(endpoint+"register?"+new URLSearchParams({
        username,
        password,
    })).then(r=>{
        if (r.status == 204){
            return;
        } else {
            throw new Error("Could not register");
        }
    })
}
export async function getAccessToken(username,password){
    return fetch(endpoint+"getAccessToken?"+new URLSearchParams({
        username,
        password,
    })).then(r=>{
        if (r.status == 200){
            return r.text();
        } else {
            throw new Error("Unauthorised");
        }
    })
}