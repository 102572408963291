import Vue from 'vue'
import VueRouter from 'vue-router'
import {  getGameInfo, getUserByName, processLogin } from '../api'
import Home from '../views/Home.vue'
import Nav from '../components/Nav.vue';

Vue.use(VueRouter)
Vue.component("Nav",Nav);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/user/:username',
    name: 'User',
    beforeEnter(to, from, next){
      getUserByName(to.params.username).then(data=>{
        to.params.data = data;
        next();
      })
    },
    component: () => import('../views/User.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/minesweeper/:gameId',
    name: 'Minesweeper',
    beforeEnter(to, from, next){
      getGameInfo(to.params.gameId).then(data=>{
        to.params.data = data;
        next();
      })
    },
    component: () => import('../views/Minesweeper.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let firstNav = true;
router.beforeEach((to, from, next) => {
  if (firstNav){
    let token = localStorage.getItem("accesstoken");
    if (token){
      processLogin(token).then(()=>{
        next();
      }).catch(()=>{
        next();
      })
    } else {
      next();
    }
  } else {
    next();
  }
  firstNav = false;
})

export default router
